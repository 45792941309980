import * as React from 'react';
import { Link } from 'gatsby';
import { Container, Grid, Label, Message } from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { CenteredImage } from '../../../components/centered_image';
import { H1, H2 } from '../../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactUsFormModal } from '../../../components/contact_us_form';

function Publications() {
  return (
    <>
      <H1>
        Publications using Salvus
      </H1>

      We try to keep track of other people's work involving Salvus.
      Below you can find an (incomplete) list of publications that use Salvus:

      <H2>
        2023
      </H2>

      <ul><li>
      <div id="ref-bissig_joint_2023" class="csl-entry" role="doc-biblioentry">
      Bissig, F., Khan, A., &amp; Giardini, D. (2023). Joint inversion of <span>PP</span> and <span>SS</span> precursor waveforms and Rayleigh wave phase velocities for global mantle transition zone structure. <em>Geophysical Journal International</em>, <em>233</em>(1), 316–337. <a href="https://doi.org/10.1093/gji/ggac451">https://doi.org/10.1093/gji/ggac451</a>
      </div></li><li>
      <div id="ref-Blom_2023" class="csl-entry" role="doc-biblioentry">
      Blom, N., Hardalupas, P.-S., &amp; Rawlinson, N. (2022). <span class="nocase">Mitigating the effect of errors in source parameters on seismic (waveform) tomography</span>. <em>Geophysical Journal International</em>, <em>232</em>(2), 810–828. <a href="https://doi.org/10.1093/gji/ggac314">https://doi.org/10.1093/gji/ggac314</a>
      </div></li><li>
      <div id="ref-Elkarmoty_2023" class="csl-entry" role="doc-biblioentry">
      Elkarmoty, M., Rupfle, J., Helal, K., Sholqamy, M., Fath-Elbab, M., Kollofrath, J., et al. (2023). Localization and shape determination of a hidden corridor in the great pyramid of Giza using non-destructive testing. <em>NDT &amp; E International</em>, 102809. https://doi.org/<a href="https://doi.org/10.1016/j.ndteint.2023.102809">https://doi.org/10.1016/j.ndteint.2023.102809</a>
      </div></li><li>
      <div id="ref-li_elastic_2023" class="csl-entry" role="doc-biblioentry">
      Li, X., Robertsson, J., &amp; Manen, D.-J. van. (2023). Elastic immersive wave experimentation. <em>Geophysical Journal International</em>, <em>233</em>(1), 724–739. <a href="https://doi.org/10.1093/gji/ggac479">https://doi.org/10.1093/gji/ggac479</a>
      </div></li><li>
      <div id="ref-marusiak_estimating_2023" class="csl-entry" role="doc-biblioentry">
      Marusiak, A. G., Tharimena, S., Panning, M. P., Vance, S. D., Boehm, C., Stähler, S., &amp; Van Driel, M. (2023). Estimating the 3D structure of the enceladus ice shell from flexural and Crary waves using seismic simulations. <em>Earth and Planetary Science Letters</em>, <em>603</em>, 117984. <a href="https://doi.org/10.1016/j.epsl.2022.117984">https://doi.org/10.1016/j.epsl.2022.117984</a>
      </div></li><li>
      <div id="ref-Peng_2023" class="csl-entry" role="doc-biblioentry">
      Peng, H., Dukalski, M., Elison, P., &amp; Vasconcelos, I. (2023). <span class="nocase">Data-driven suppression of short-period multiples from laterally varying thin-layered overburden structures</span>. <em>Geophysics</em>, V59–V73. <a href="https://doi.org/10.1190/geo2022-0241.1">https://doi.org/10.1190/geo2022-0241.1</a>
      </div></li><li>
      <div id="ref-Rodriguez_2023" class="csl-entry" role="doc-biblioentry">
      Rodriguez Piceda, C., Gao, Y.-J., Cacace, M., Scheck-Wenderoth, M., Bott, J., Strecker, M., &amp; Tilmann, F. (2023). The influence of mantle hydration and flexure on slab seismicity in the southern Central Andes. <em>Communications Earth &amp; Environment</em>, <em>4</em>(1), e2022JB024231. https://doi.org/<a href="https://doi.org/10.1038/s43247-023-00729-1">https://doi.org/10.1038/s43247-023-00729-1</a>
      </div></li><li>
      <div id="ref-Yust_2023" class="csl-entry" role="doc-biblioentry">
      Yust, M. B. S., Cox, B. R., Vantassel, J. P., Hubbard, P. G., Boehm, C., &amp; Krischer, L. (2023). Near-surface 2D imaging via FWI of DAS data: An examination on the impacts of FWI starting model. <em>Geosciences</em>, <em>13</em>(3). <a href="https://doi.org/10.3390/geosciences13030063">https://doi.org/10.3390/geosciences13030063</a>
      </div>
      </li></ul>

      <H2>
        2022
      </H2>

      <ul><li>
      <div id="ref-atterholt_bayesian_2022" class="csl-entry" role="doc-biblioentry">
      Atterholt, J., &amp; Ross, Z. E. (2022). Bayesian framework for inversion of second-order stress glut moments: Application to the 2019 ridgecrest sequence mainshock. <em>Journal of Geophysical Research: Solid Earth</em>, <em>127</em>(4), e2021JB023780. <a href="https://doi.org/10.1029/2021JB023780">https://doi.org/10.1029/2021JB023780</a>
      </div></li><li>
      <div id="ref-atterholt_fault_2022" class="csl-entry" role="doc-biblioentry">
      Atterholt, J., Zhan, Z., &amp; Yang, Y. (2022). Fault zone imaging with distributed acoustic sensing: Body-to-surface wave scattering. <em>Journal of Geophysical Research: Solid Earth</em>, <em>127</em>(11), e2022JB025052. <a href="https://doi.org/10.1029/2022JB025052">https://doi.org/10.1029/2022JB025052</a>
      </div></li><li>
      <div id="ref-aubry_benchmark_2022" class="csl-entry" role="doc-biblioentry">
      Aubry, J.-F., Bates, O., Boehm, C., Butts Pauly, K., Christensen, D., Cueto, C., et al. (2022). Benchmark problems for transcranial ultrasound simulation: Intercomparison of compressional wave models. <em>The Journal of the Acoustical Society of America</em>, <em>152</em>(2), 1003–1019. <a href="https://doi.org/10.1121/10.0013426">https://doi.org/10.1121/10.0013426</a>
      </div></li><li>
      <div id="ref-BISSIG2022117387" class="csl-entry" role="doc-biblioentry">
      Bissig, F., Khan, A., &amp; Giardini, D. (2022). Evidence for basalt enrichment in the mantle transition zone from inversion of triplicated p- and s-waveforms. <em>Earth and Planetary Science Letters</em>, <em>580</em>, 117387. <a href="https://doi.org/10.1016/j.epsl.2022.117387">https://doi.org/10.1016/j.epsl.2022.117387</a>
      </div></li><li>
      <div id="ref-blom_mitigating_2023" class="csl-entry" role="doc-biblioentry">
      Blom, N., Hardalupas, P.-S., &amp; Rawlinson, N. (2023). Mitigating the effect of errors in source parameters on seismic (waveform) tomography. <em>Geophysical Journal International</em>, <em>232</em>(2), 810–828. <a href="https://doi.org/10.1093/gji/ggac314">https://doi.org/10.1093/gji/ggac314</a>
      </div></li><li>
      <div id="ref-brackenhoff_virtual_2022" class="csl-entry" role="doc-biblioentry">
      Brackenhoff, J., Neut, J. van der, Meles, G., Marty, P., &amp; Boehm, C. (2022). Virtual ultrasound transducers in the human brain. In <em>Medical imaging 2022: Ultrasonic imaging and tomography</em> (Vol. 12038, pp. 169–186). <span>SPIE</span>. <a href="https://doi.org/10.1117/12.2611779">https://doi.org/10.1117/12.2611779</a>
      </div></li><li>
      <div id="ref-brinkman_situ_2022" class="csl-entry" role="doc-biblioentry">
      Brinkman, N., Schmelzbach, C., Sollberger, D., Pierick, J. ten, Edme, P., Haag, T., et al. (2022). In situ regolith seismic velocity measurement at the <span>InSight</span> landing site on Mars. <em>Journal of Geophysical Research: Planets</em>, <em>127</em>(10), e2022JE007229. <a href="https://doi.org/10.1029/2022JE007229">https://doi.org/10.1029/2022JE007229</a>
      </div></li><li>
      <div id="ref-DMITROVSKII2022114714" class="csl-entry" role="doc-biblioentry">
      Dmitrovskii, A. A., Khan, A., Boehm, C., Bagheri, A., &amp; van Driel, M. (2022). Constraints on the interior structure of phobos from tidal deformation modeling. <em>Icarus</em>, <em>372</em>, 114714. <a href="https://doi.org/10.1016/j.icarus.2021.114714">https://doi.org/10.1016/j.icarus.2021.114714</a>
      </div></li><li>
      <div id="ref-edme_distributed_2022" class="csl-entry" role="doc-biblioentry">
      Edme, P., Kiers, T., Paitz, P., &amp; Robertsson, J. O. A. (2022). Distributed fiber-optic sensing for local ground-roll estimation and attenuation (Vol. 2022, pp. 1–5). Presented at the 83rd <span>EAGE</span> annual conference &amp; exhibition, European Association of Geoscientists &amp; Engineers. <a href="https://doi.org/10.3997/2214-4609.202210148">https://doi.org/10.3997/2214-4609.202210148</a>
      </div></li><li>
      <div id="ref-fichtner_fiberoptic_2022" class="csl-entry" role="doc-biblioentry">
      Fichtner, A., Klaasen, S., Thrastarson, S., Çubuk‐Sabuncu, Y., Paitz, P., &amp; Jónsdóttir, K. (2022). Fiber‐optic observation of volcanic tremor through floating ice sheet resonance. <em>The Seismic Record</em>, <em>2</em>(3), 148–155. <a href="https://doi.org/10.1785/0320220010">https://doi.org/10.1785/0320220010</a>
      </div></li><li>
      <div id="ref-keil_estimation_2022" class="csl-entry" role="doc-biblioentry">
      Keil, S., Wassermann, J., &amp; Megies, T. (2022). Estimation of ground motion due to induced seismicity at a geothermal power plant near Munich, Germany, using numerical simulations. <em>Geothermics</em>, <em>106</em>, 102577. <a href="https://doi.org/10.1016/j.geothermics.2022.102577">https://doi.org/10.1016/j.geothermics.2022.102577</a>
      </div></li><li>
      <div id="ref-koedel_seismic_2022" class="csl-entry" role="doc-biblioentry">
      Koedel, U., Stork, A., Thomas, P. J., Zhou, W., David, A., Maurer, H., et al. (2022, November 18). Seismic cross-hole surveying with conventional seismic and distributed acoustic sensing (<span>DAS</span>) at the svelvik test-site. Rochester, <span>NY</span>. <a href="https://doi.org/10.2139/ssrn.4274670">https://doi.org/10.2139/ssrn.4274670</a>
      </div></li><li>
      <div id="ref-korta_martiartu_toward_2022" class="csl-entry" role="doc-biblioentry">
      Korta Martiartu, N., Simutė, S., Jaeger, M., Frauenfelder, T., &amp; Rominger, M. B. (2022). Toward speed-of-sound anisotropy quantification in muscle with pulse-echo ultrasound. <em><span>IEEE</span> Transactions on Ultrasonics, Ferroelectrics, and Frequency Control</em>, <em>69</em>(8), 2499–2511. <a href="https://doi.org/10.1109/TUFFC.2022.3189184">https://doi.org/10.1109/TUFFC.2022.3189184</a>
      </div></li><li>
      <div id="ref-mondaic_ag_full-waveform_2022" class="csl-entry" role="doc-biblioentry">
      Krischer, L., Strobach, E., Boehm, C., Afansasiev M., &amp; Angst, U. (2022). Full-waveform inversion of ultrasonic echo signals to evaluate grouting quality of tendon ducts in post-tensioned concrete structures. <em>E-Journal of Nondestructive Testing</em>, <em>27</em>(9). <a href="https://doi.org/10.58286/27317">https://doi.org/10.58286/27317</a>
      </div></li><li>
      <div id="ref-kufner_devastating_2022" class="csl-entry" role="doc-biblioentry">
      Kufner, S.-K., Bie, L., Gao, Y.-J., Lindner, M., Waizy, H., Kakar, N., &amp; Reitbrock, A. (2022). <em>The devastating 2022 M6.2 Afghanistan earthquake: Challenges, processes and implications</em> (preprint). In Review. <a href="https://doi.org/10.21203/rs.3.rs-2238516/v1">https://doi.org/10.21203/rs.3.rs-2238516/v1</a>
      </div></li><li>
      <div id="ref-li_internal_2022" class="csl-entry" role="doc-biblioentry">
      Li, X., Robertsson, J., Curtis, A., &amp; Manen, D.-J. van. (2022). Internal absorbing boundary conditions for closed-aperture wavefield decomposition in solid media with unknown interiors. <em>The Journal of the Acoustical Society of America</em>, <em>152</em>(1), 313–329. <a href="https://doi.org/10.1121/10.0012578">https://doi.org/10.1121/10.0012578</a>
      </div></li><li>
      <div id="ref-limberger_development_2022" class="csl-entry" role="doc-biblioentry">
      Limberger, F., R\"umpker, G., Lindenfeld, M., &amp; Deckert, H. (2022). Development of a numerical modelling method to predict the seismic signals generated by wind farms. <em>Scientific Reports</em>, <em>12</em>(1), 15516. <a href="https://doi.org/10.1038/s41598-022-19799-w">https://doi.org/10.1038/s41598-022-19799-w</a>
      </div></li><li>
      <div id="ref-ma_seismic_2022" class="csl-entry" role="doc-biblioentry">
      Ma, J., Bunge, H.-P., Thrastarson, S., Fichtner, A., Herwaarden, D.-P. van, Tian, Y., et al. (2022). Seismic full-waveform inversion of the crust-mantle structure beneath china and adjacent regions. <em>Journal of Geophysical Research: Solid Earth</em>, <em>127</em>(9), e2022JB024957. <a href="https://doi.org/10.1029/2022JB024957">https://doi.org/10.1029/2022JB024957</a>
      </div></li><li>
      <div id="ref-marty_elastic_2022" class="csl-entry" role="doc-biblioentry">
      Marty, P., Boehm, C., &amp; Fichtner, A. (2022). Elastic full-waveform inversion for transcranial ultrasound computed tomography using optimal transport. In <em>2022 <span>IEEE</span> international ultrasonics symposium (<span>IUS</span>)</em> (pp. 1–4). <a href="https://doi.org/10.1109/IUS54386.2022.9957394">https://doi.org/10.1109/IUS54386.2022.9957394</a>
      </div></li><li>
      <div id="ref-marty_full-waveform_2022" class="csl-entry" role="doc-biblioentry">
      Marty, P., Boehm, C., Paverd, C., Rominger, M., &amp; Fichtner, A. (2022). Full-waveform ultrasound modeling of soft tissue-bone interactions using conforming hexahedral meshes. In <em>Medical imaging 2022: Physics of medical imaging</em> (Vol. 12031, pp. 877–891). <span>SPIE</span>. <a href="https://doi.org/10.1117/12.2611548">https://doi.org/10.1117/12.2611548</a>
      </div></li><li>
      <div id="ref-rodgers_wus256_2022" class="csl-entry" role="doc-biblioentry">
      Rodgers, A., Krischer, L., Afanasiev, M., Boehm, C., Doody, C., Chiang, A., &amp; Simmons, N. (2022). <span>WUS</span>256: An adjoint waveform tomography model of the crust and upper mantle of the Western United States for improved waveform simulations. <em>Journal of Geophysical Research: Solid Earth</em>, <em>127</em>(7), e2022JB024549. <a href="https://doi.org/10.1029/2022JB024549">https://doi.org/10.1029/2022JB024549</a>
      </div></li><li>
      <div id="ref-rodriguez_piceda_thermomechanical_2022" class="csl-entry" role="doc-biblioentry">
      Rodriguez Piceda, C. (2022). <em>Thermomechanical state of the southern central andes : Implications for active deformation patterns in the transition from flat to steep subduction</em> (PhD thesis). Universität Potsdam. <a href="https://doi.org/10.25932/publishup-54927">https://doi.org/10.25932/publishup-54927</a>
      </div></li><li>
      <div id="ref-sager_modelling_2022" class="csl-entry" role="doc-biblioentry">
      Sager, K., Tsai, V. C., Sheng, Y., Brenguier, F., Boué, P., Mordret, A., &amp; Igel, H. (2022). Modelling p waves in seismic noise correlations: Advancing fault monitoring using train traffic sources. <em>Geophysical Journal International</em>, <em>228</em>(3), 1556–1567. <a href="https://doi.org/10.1093/gji/ggab389">https://doi.org/10.1093/gji/ggab389</a>
      </div></li><li>
      <div id="ref-https://doi.org/10.1029/2021GL095287" class="csl-entry" role="doc-biblioentry">
      Spica, Z. J., Castellanos, J. C., Viens, L., Nishida, K., Akuhara, T., Shinohara, M., &amp; Yamada, T. (2022). Subsurface imaging with ocean-bottom distributed acoustic sensing and water phases reverberations. <em>Geophysical Research Letters</em>, <em>49</em>(2), e2021GL095287. <a href="https://doi.org/10.1029/2021GL095287">https://doi.org/10.1029/2021GL095287</a>
      </div></li><li>
      <div id="ref-thrastarson_data-adaptive_2022" class="csl-entry" role="doc-biblioentry">
      Thrastarson, S., Herwaarden, D.-P. van, Krischer, L., Boehm, C., Driel, M. van, Afanasiev, M., &amp; Fichtner, A. (2022). Data-adaptive global full-waveform inversion. <em>Geophysical Journal International</em>, <em>230</em>(2), 1374–1393. <a href="https://doi.org/10.1093/gji/ggac122">https://doi.org/10.1093/gji/ggac122</a>
      </div></li><li>
      <div id="ref-ulrich_analyzing_2022" class="csl-entry" role="doc-biblioentry">
      Ulrich, Ines E., Boehm, C., Zunino, A., &amp; Fichtner, A. (2022). Analyzing resolution and model uncertainties for ultrasound computed tomography using hessian information. In <em>Medical imaging 2022: Ultrasonic imaging and tomography</em> (Vol. 12038, pp. 48–60). <span>SPIE</span>. <a href="https://doi.org/10.1117/12.2608546">https://doi.org/10.1117/12.2608546</a>
      </div></li><li>
      <div id="ref-ulrich_diffuse_2022" class="csl-entry" role="doc-biblioentry">
      Ulrich, Ines Elisa, Boehm, C., Zunino, A., Bösch, C., &amp; Fichtner, A. (2022). Diffuse ultrasound computed tomography. <em>The Journal of the Acoustical Society of America</em>, <em>151</em>(6), 3654–3668. <a href="https://doi.org/10.1121/10.0011540">https://doi.org/10.1121/10.0011540</a>
      </div></li><li>
      <div id="ref-WASSERMANN2022107499" class="csl-entry" role="doc-biblioentry">
      Wassermann, J., Braun, T., Ripepe, M., Bernauer, F., Guattari, F., &amp; Igel, H. (2022). The use of 6DOF measurement in volcano seismology – a first application to stromboli volcano. <em>Journal of Volcanology and Geothermal Research</em>, <em>424</em>, 107499. <a href="https://doi.org/10.1016/j.jvolgeores.2022.107499">https://doi.org/10.1016/j.jvolgeores.2022.107499</a>
      </div></li><li>
      <div id="ref-https://doi.org/10.1029/2021JB022930" class="csl-entry" role="doc-biblioentry">
      Wehner, D., Blom, N., Rawlinson, N., Daryono, Boehm, C., Miller, M. S., et al. (2022). <span class="nocase">SASSY21: A 3-D Seismic Structural Model of the Lithosphere and Underlying Mantle Beneath Southeast Asia From Multi-Scale Adjoint Waveform Tomography</span>. <em>Journal of Geophysical Research: Solid Earth</em>, <em>127</em>(3), e2021JB022930. https://doi.org/<a href="https://doi.org/10.1029/2021JB022930">https://doi.org/10.1029/2021JB022930</a>
      </div></li><li>
      <div id="ref-wehner_sassier22_2022" class="csl-entry" role="doc-biblioentry">
      Wehner, D., Rawlinson, N., Greenfield, T., Daryono, Miller, M. S., Supendi, P., et al. (2022). <span>SASSIER</span>22: Full-waveform tomography of the eastern indonesian region that includes topography, bathymetry, and the fluid ocean. <em>Geochemistry, Geophysics, Geosystems</em>, <em>23</em>(11), e2022GC010563. <a href="https://doi.org/10.1029/2022GC010563">https://doi.org/10.1029/2022GC010563</a>
      </div>
      </li></ul>

      <H2>
        2021
      </H2>

      <ul><li>
      <div id="ref-boehm2021usct" class="csl-entry" role="doc-biblioentry">
      Boehm, C., Hopp, T., &amp; Ruiter, N. (2021). USCT data challenge 2019. In <em>Proceedings of the international workshop on medical ultrasound tomography</em> (pp. 107–115). KIT Scientific Publishing. <a href="https://doi.org/10.5445/IR/1000128317">https://doi.org/10.5445/IR/1000128317</a>
      </div></li><li>
      <div id="ref-https://doi.org/10.1029/2021JB022462" class="csl-entry" role="doc-biblioentry">
      Castellanos, J. C., &amp; Clayton, R. W. (2021). The fine-scale structure of long beach, california, and its impact on ground motion acceleration. <em>Journal of Geophysical Research: Solid Earth</em>, <em>126</em>(12), e2021JB022462. <a href="https://doi.org/10.1029/2021JB022462">https://doi.org/10.1029/2021JB022462</a>
      </div></li><li>
      <div id="ref-https://doi.org/10.1029/2020JE006670" class="csl-entry" role="doc-biblioentry">
      Driel, M. van, Ceylan, S., Clinton, J. F., Giardini, D., Horleston, A., Margerin, L., et al. (2021). High-frequency seismic events on mars observed by InSight. <em>Journal of Geophysical Research: Planets</em>, <em>126</em>(2), e2020JE006670. <a href="https://doi.org/10.1029/2020JE006670">https://doi.org/10.1029/2020JE006670</a>
      </div></li><li>
      <div id="ref-https://doi.org/10.1029/2021GL095509" class="csl-entry" role="doc-biblioentry">
      Gao, Y., Yuan, X., Heit, B., Tilmann, F., Herwaarden, D.-P. van, Thrastarson, S., et al. (2021). Impact of the juan fernandez ridge on the pampean flat subduction inferred from full waveform inversion. <em>Geophysical Research Letters</em>, <em>48</em>(21), e2021GL095509. <a href="https://doi.org/10.1029/2021GL095509">https://doi.org/10.1029/2021GL095509</a>
      </div></li><li>
      <div id="ref-doi:10.1137/20M1332748" class="csl-entry" role="doc-biblioentry">
      Hapla, V., Knepley, M. G., Afanasiev, M., Boehm, C., Driel, M. van, Krischer, L., &amp; Fichtner, A. (2021). Fully parallel mesh i/o using PETSc DMPlex with an application to waveform modeling. <em>SIAM Journal on Scientific Computing</em>, <em>43</em>(2), C127–C153. <a href="https://doi.org/10.1137/20M1332748">https://doi.org/10.1137/20M1332748</a>
      </div></li><li>
      <div id="ref-doi:10.1061/9780784483404.041" class="csl-entry" role="doc-biblioentry">
      Kordjazi, A., &amp; Coe, J. T. (2021a). An experimental design approach for structural integrity testing of drilled shafts using full waveform inversion. In <em>IFCEE 2021</em> (pp. 453–462). <a href="https://doi.org/10.1061/9780784483404.041">https://doi.org/10.1061/9780784483404.041</a>
      </div></li><li>
      <div id="ref-doi:10.1061/9780784483404.049" class="csl-entry" role="doc-biblioentry">
      Kordjazi, A., &amp; Coe, J. T. (2021b). Numerical simulation of full waveform tomography to evaluate the geological conditions beneath the base of drilled shaft excavations. In <em>IFCEE 2021</em> (pp. 538–546). <a href="https://doi.org/10.1061/9780784483404.049">https://doi.org/10.1061/9780784483404.049</a>
      </div></li><li>
      <div id="ref-kordjazi2021nondestructive" class="csl-entry" role="doc-biblioentry">
      Kordjazi, A., Coe, J. T., &amp; Afanasiev, M. (2021). Nondestructive evaluation of drilled shaft construction anomalies using full waveform tomography of simulated crosshole measurements. <em>Journal of Nondestructive Evaluation</em>, <em>40</em>(1), 1–13. <a href="https://doi.org/10.1007/s10921-020-00728-8">https://doi.org/10.1007/s10921-020-00728-8</a>
      </div></li><li>
      <div id="ref-KortaMartiartu2021" class="csl-entry" role="doc-biblioentry">
      Korta Martiartu, N., Simutė, S., Frauenfelder, T., &amp; Rominger, M. B. (2021). <span class="nocase">Ultrasound longitudinal-wave anisotropy estimation in muscle tissue</span>. <a href="https://doi.org/10.36227/techrxiv.15104529.v2">https://doi.org/10.36227/techrxiv.15104529.v2</a>
      </div></li><li>
      <div id="ref-doi:10.1121/10.0003706" class="csl-entry" role="doc-biblioentry">
      Li, X., Becker, T., Ravasi, M., Robertsson, J., &amp; Manen, D.-J. van. (2021). Closed-aperture unbounded acoustics experimentation using multidimensional deconvolution. <em>The Journal of the Acoustical Society of America</em>, <em>149</em>(3), 1813–1828. <a href="https://doi.org/10.1121/10.0003706">https://doi.org/10.1121/10.0003706</a>
      </div></li><li>
      <div id="ref-doi:10.1061/9780784483428.040" class="csl-entry" role="doc-biblioentry">
      Mahvelati, S., Kordjazi, A., &amp; Coe, J. T. (2021). Evaluation of pinnacle bedrock topography using surface waves: A numerical simulation of full waveform tomography. In <em>IFCEE 2021</em> (pp. 394–403). <a href="https://doi.org/10.1061/9780784483428.040">https://doi.org/10.1061/9780784483428.040</a>
      </div></li><li>
      <div id="ref-10.1117/12.2581029" class="csl-entry" role="doc-biblioentry">
      Marty, P., Boehm, C., &amp; Fichtner, A. (2021). <span class="nocase">Acoustoelastic full-waveform inversion for transcranial ultrasound computed tomography</span>. In B. C. Byram &amp; N. V. Ruiter (Eds.), <em>Medical imaging 2021: Ultrasonic imaging and tomography</em> (Vol. 11602, pp. 210–229). International Society for Optics; Photonics; SPIE. <a href="https://doi.org/10.1117/12.2581029">https://doi.org/10.1117/12.2581029</a>
      </div></li><li>
      <div id="ref-doi:10.1190/geo2020-0914.1" class="csl-entry" role="doc-biblioentry">
      Peng, H., Vasconcelos, I., Sripanich, Y., &amp; Zhang, L. (2021). An analysis of acquisition-related subsampling effects on marchenko focusing, redatuming, and primary estimation. <em>GEOPHYSICS</em>, <em>86</em>(5), WC75–WC88. <a href="https://doi.org/10.1190/geo2020-0914.1">https://doi.org/10.1190/geo2020-0914.1</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggab389" class="csl-entry" role="doc-biblioentry">
      Sager, K., Tsai, V. C., Sheng, Y., Brenguier, F., Boué, P., Mordret, A., &amp; Igel, H. (2021). <span class="nocase">Modelling P waves in seismic noise correlations: advancing fault monitoring using train traffic sources</span>. <em>Geophysical Journal International</em>, <em>228</em>(3), 1556–1567. <a href="https://doi.org/10.1093/gji/ggab389">https://doi.org/10.1093/gji/ggab389</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggab275" class="csl-entry" role="doc-biblioentry">
      Thomsen, H. R., Koene, E. F. M., Robertsson, J. O. A., &amp; Manen, D.-J. van. (2021). <span class="nocase">FD-injection-based elastic wavefield separation for open and closed configurations</span>. <em>Geophysical Journal International</em>, <em>227</em>(3), 1646–1664. <a href="https://doi.org/10.1093/gji/ggab275">https://doi.org/10.1093/gji/ggab275</a>
      </div></li><li>
      <div id="ref-10.1785/0320210026" class="csl-entry" role="doc-biblioentry">
      Yang, Y., Gao, A. F., Castellanos, J. C., Ross, Z. E., Azizzadenesheli, K., &amp; Clayton, R. W. (2021). <span class="nocase">Seismic Wave Propagation and Inversion with Neural Operators</span>. <em>The Seismic Record</em>, <em>1</em>(3), 126–134. <a href="https://doi.org/10.1785/0320210026">https://doi.org/10.1785/0320210026</a>
      </div>
      </li></ul>

      <H2>
        2020
      </H2>

      <ul><li>
      <div id="ref-https://doi.org/10.1029/2020JB020350" class="csl-entry" role="doc-biblioentry">
      Bissig, F., Khan, A., Tauzin, B., Sossi, P. A., Munch, F. D., &amp; Giardini, D. (2021). Multifrequency inversion of ps and sp receiver functions: Methodology and application to USArray data. <em>Journal of Geophysical Research: Solid Earth</em>, <em>126</em>(2), e2020JB020350. <a href="https://doi.org/10.1029/2020JB020350">https://doi.org/10.1029/2020JB020350</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggaa058" class="csl-entry" role="doc-biblioentry">
      Driel, M. van, Boehm, C., Krischer, L., &amp; Afanasiev, M. (2020). <span class="nocase">Accelerating numerical wave propagation using wavefield adapted meshes. Part I: forward and adjoint modelling</span>. <em>Geophysical Journal International</em>, <em>221</em>(3), 1580–1590. <a href="https://doi.org/10.1093/gji/ggaa058">https://doi.org/10.1093/gji/ggaa058</a>
      </div></li><li>
      <div id="ref-https://doi.org/10.1029/2020EA001118" class="csl-entry" role="doc-biblioentry">
      Drilleau, M., Beucler, É., Lognonné, P., Panning, M. P., Knapmeyer-Endrun, B., Banerdt, W. B., et al. (2020). MSS/1: Single-station and single-event marsquake inversion. <em>Earth and Space Science</em>, <em>7</em>(12), e2020EA001118. <a href="https://doi.org/10.1029/2020EA001118">https://doi.org/10.1029/2020EA001118</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggaa079" class="csl-entry" role="doc-biblioentry">
      Herwaarden, D. P. van, Boehm, C., Afanasiev, M., Thrastarson, S., Krischer, L., Trampert, J., &amp; Fichtner, A. (2020). <span class="nocase">Accelerated full-waveform inversion using dynamic mini-batches</span>. <em>Geophysical Journal International</em>, <em>221</em>(2), 1427–1438. <a href="https://doi.org/10.1093/gji/ggaa079">https://doi.org/10.1093/gji/ggaa079</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggaa459" class="csl-entry" role="doc-biblioentry">
      Herwaarden, D. P. van, Afanasiev, M., Thrastarson, S., &amp; Fichtner, A. (2020). <span class="nocase">Evolutionary full-waveform inversion</span>. <em>Geophysical Journal International</em>, <em>224</em>(1), 306–311. <a href="https://doi.org/10.1093/gji/ggaa459">https://doi.org/10.1093/gji/ggaa459</a>
      </div></li><li>
      <div id="ref-doi:10.1061/9780784482780.018" class="csl-entry" role="doc-biblioentry">
      Kordjazi, A., Coe, J. T., &amp; Afanasiev, M. (2020a). A hybrid multi-scale full waveform inversion approach to evaluate the structural integrity of drilled shafts. In <em>Geo-congress 2020</em> (pp. 190–199). <a href="https://doi.org/10.1061/9780784482780.018">https://doi.org/10.1061/9780784482780.018</a>
      </div></li><li>
      <div id="ref-doi:10.1061/9780784482803.047" class="csl-entry" role="doc-biblioentry">
      Kordjazi, A., Coe, J. T., &amp; Afanasiev, M. (2020b). The use of the spectral element method for modeling stress wave propagation in non-destructive testing applications for drilled shafts. In <em>Geo-congress 2020</em> (pp. 434–443). <a href="https://doi.org/10.1061/9780784482803.047">https://doi.org/10.1061/9780784482803.047</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggaa553" class="csl-entry" role="doc-biblioentry">
      Mauerberger, A., Maupin, V., Gudmundsson, Ó., &amp; Tilmann, F. (2020). <span class="nocase">Anomalous azimuthal variations with 360° periodicity of Rayleigh phase velocities observed in Scandinavia</span>. <em>Geophysical Journal International</em>, <em>224</em>(3), 1684–1704. <a href="https://doi.org/10.1093/gji/ggaa553">https://doi.org/10.1093/gji/ggaa553</a>
      </div></li><li>
      <div id="ref-https://doi.org/10.1029/2019JB018644" class="csl-entry" role="doc-biblioentry">
      Sager, K., Boehm, C., Ermert, L., Krischer, L., &amp; Fichtner, A. (2020). Global-scale full-waveform ambient noise inversion. <em>Journal of Geophysical Research: Solid Earth</em>, <em>125</em>(4), e2019JB018644. <a href="https://doi.org/10.1029/2019JB018644">https://doi.org/10.1029/2019JB018644</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggaa065" class="csl-entry" role="doc-biblioentry">
      Thrastarson, S., Driel, M. van, Krischer, L., Boehm, C., Afanasiev, M., Herwaarden, D.-P. van, &amp; Fichtner, A. (2020). <span class="nocase">Accelerating numerical wave propagation by wavefield adapted meshes. Part II: full-waveform inversion</span>. <em>Geophysical Journal International</em>, <em>221</em>(3), 1591–1604. <a href="https://doi.org/10.1093/gji/ggaa065">https://doi.org/10.1093/gji/ggaa065</a>
      </div></li><li>
      <div id="ref-10.1117/12.2559852" class="csl-entry" role="doc-biblioentry">
      Ulrich, I. E., Boehm, C., &amp; Fichtner, A. (2020). <span class="nocase">Random field interferometry for medical ultrasound</span>. In B. C. Byram &amp; N. V. Ruiter (Eds.), <em>Medical imaging 2020: Ultrasonic imaging and tomography</em> (Vol. 11319, pp. 217–228). International Society for Optics; Photonics; SPIE. <a href="https://doi.org/10.1117/12.2559852">https://doi.org/10.1117/12.2559852</a>
      </div>
      </li></ul>

      <H2>
        2019
      </H2>

      <ul><li>
      <div id="ref-doi:10.1121/1.5122291" class="csl-entry" role="doc-biblioentry">
      Korta Martiartu, N., Boehm, C., Hapla, V., Maurer, H., Balic, I. J., &amp; Fichtner, A. (2019). Optimal experimental design for joint reflection-transmission ultrasound breast imaging: From ray- to wave-based methods. <em>The Journal of the Acoustical Society of America</em>, <em>146</em>(2), 1252–1264. <a href="https://doi.org/10.1121/1.5122291">https://doi.org/10.1121/1.5122291</a>
      </div>
      </li></ul>

      <H2>
        2018
      </H2>

      <ul><li>
      <div id="ref-doi:10.1190/segam2018-2998264.1" class="csl-entry" role="doc-biblioentry">
      Afanasiev, M., Boehm, C., Driel, M. van, Krischer, L., &amp; Fichtner, A. (2018). Flexible high-performance multiphysics waveform modeling on unstructured spectral-element meshes. In <em>SEG technical program expanded abstracts 2018</em> (pp. 4035–4039). <a href="https://doi.org/10.1190/segam2018-2998264.1">https://doi.org/10.1190/segam2018-2998264.1</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggy469" class="csl-entry" role="doc-biblioentry">
      Afanasiev, M., Boehm, C., Driel, M. van, Krischer, L., Rietmann, M., May, D. A., et al. (2018). <span class="nocase">Modular and flexible spectral-element waveform modelling in two and three dimensions</span>. <em>Geophysical Journal International</em>, <em>216</em>(3), 1675–1692. <a href="https://doi.org/10.1093/gji/ggy469">https://doi.org/10.1093/gji/ggy469</a>
      </div></li><li>
      <div id="ref-bissig2018detectability" class="csl-entry" role="doc-biblioentry">
      Bissig, F., Khan, A., Driel, M. van, Stähler, S. C., Giardini, D., Panning, M., et al. (2018). On the detectability and use of normal modes for determining interior structure of mars. <em>Space Science Reviews</em>, <em>214</em>(8), 1–28. <a href="https://doi.org/10.1007/s11214-018-0547-9">https://doi.org/10.1007/s11214-018-0547-9</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggy295" class="csl-entry" role="doc-biblioentry">
      Boehm, C., &amp; Fichtner, A. (2018). <span class="nocase">Lazy wave propagation</span>. <em>Geophysical Journal International</em>, <em>216</em>(2), 984–990. <a href="https://doi.org/10.1093/gji/ggy295">https://doi.org/10.1093/gji/ggy295</a>
      </div></li><li>
      <div id="ref-10.1117/12.2293299" class="csl-entry" role="doc-biblioentry">
      Boehm, C., Korta Martiartu, N., Vinard, N., Balic, I. J., &amp; Fichtner, A. (2018). <span class="nocase">Time-domain spectral-element ultrasound waveform tomography using a stochastic quasi-Newton method</span>. In N. Duric &amp; B. C. Byram (Eds.), <em>Medical imaging 2018: Ultrasonic imaging and tomography</em> (Vol. 10580, pp. 92–100). International Society for Optics; Photonics; SPIE. <a href="https://doi.org/10.1117/12.2293299">https://doi.org/10.1117/12.2293299</a>
      </div></li><li>
      <div id="ref-clinton2018marsquake" class="csl-entry" role="doc-biblioentry">
      Clinton, J., Giardini, D., Böse, M., Ceylan, S., Driel, M. van, Euchner, F., et al. (2018). The marsquake service: Securing daily analysis of SEIS data and building the martian seismicity catalogue for InSight. <em>Space Science Reviews</em>, <em>214</em>(8), 1–33. <a href="https://doi.org/10.1007/s11214-018-0567-5">https://doi.org/10.1007/s11214-018-0567-5</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggy528" class="csl-entry" role="doc-biblioentry">
      Paitz, P., Sager, K., &amp; Fichtner, A. (2018). <span class="nocase">Rotation and strain ambient noise interferometry</span>. <em>Geophysical Journal International</em>, <em>216</em>(3), 1938–1952. <a href="https://doi.org/10.1093/gji/ggy528">https://doi.org/10.1093/gji/ggy528</a>
      </div></li><li>
      <div id="ref-https://doi.org/10.1029/2018JB016042" class="csl-entry" role="doc-biblioentry">
      Sager, K., Boehm, C., Ermert, L., Krischer, L., &amp; Fichtner, A. (2018). Sensitivity of seismic noise correlation functions to global noise sources. <em>Journal of Geophysical Research: Solid Earth</em>, <em>123</em>(8), 6911–6921. <a href="https://doi.org/10.1029/2018JB016042">https://doi.org/10.1029/2018JB016042</a>
      </div></li><li>
      <div id="ref-10.1093/gji/ggx542" class="csl-entry" role="doc-biblioentry">
      Sollberger, D., Greenhalgh, S. A., Schmelzbach, C., Van Renterghem, C., &amp; Robertsson, J. O. A. (2017). <span class="nocase">6-C polarization analysis using point measurements of translational and rotational ground-motion: theory and applications</span>. <em>Geophysical Journal International</em>, <em>213</em>(1), 77–97. <a href="https://doi.org/10.1093/gji/ggx542">https://doi.org/10.1093/gji/ggx542</a>
      </div></li><li>
      <div id="ref-10.1117/12.2293600" class="csl-entry" role="doc-biblioentry">
      Vinard, N., Korta Martiartu, N., Boehm, C., Balic, I. J., &amp; Fichtner, A. (2018). <span class="nocase">Optimized transducer configuration for ultrasound waveform tomography in breast cancer detection</span>. In N. Duric &amp; B. C. Byram (Eds.), <em>Medical imaging 2018: Ultrasonic imaging and tomography</em> (Vol. 10580, pp. 101–115). International Society for Optics; Photonics; SPIE. <a href="https://doi.org/10.1117/12.2293600">https://doi.org/10.1117/12.2293600</a>
      </div>
      </li></ul>

      <H2>
        2017
      </H2>

      <ul><li>
      <div id="ref-10.1093/gji/ggx189" class="csl-entry" role="doc-biblioentry">
      Dales, P., Audet, P., Olivier, G., &amp; Mercier, J.-P. (2017). <span class="nocase">Interferometric methods for spatio temporal seismic monitoring in underground mines</span>. <em>Geophysical Journal International</em>, <em>210</em>(2), 731–742. <a href="https://doi.org/10.1093/gji/ggx189">https://doi.org/10.1093/gji/ggx189</a>
      </div>
      </li><li>
      <div id="ref-doi:10.1190/segam2017-17725405.1" class="csl-entry" role="doc-biblioentry">
      Sollberger, D., Schmelzbach, C., Renterghem, C. V., Robertsson, J., &amp; Greenhalgh, S. (2017). Automated, six-component, single-station ground-roll identification and suppression by combined processing of translational and rotational ground motion. In <em>SEG technical program expanded abstracts 2017</em> (pp. 5064–5068). <a href="https://doi.org/10.1190/segam2017-17725405.1">https://doi.org/10.1190/segam2017-17725405.1</a>
      </div>
      </li></ul>

      <H2>
      Salvus in other news
      </H2>

      <ul><li>
      <a href="https://www.cscs.ch/science/physics/2018/simulations-for-insight-mars-mission/">Simulations for the InSight Mars mission</a>
      </li></ul>

      <Grid style={{ marginTop: '5ex' }}>
        <Grid.Row centered>
          The Salvus software suite is Mondaic's flagship code to solve problems like
          the ones above
        </Grid.Row>
        <Grid.Row centered>
          ... and many more!
        </Grid.Row>
        <Grid.Row centered>
          <ContactUsFormModal />
        </Grid.Row>
      </Grid>
    </>
  );
}

export default withLayout(Publications);
